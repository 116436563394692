import {
  Button,
  Container,
  Fade,
  Grid,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import bg from 'assets/images/bg.jpg';
import classNames from 'classnames';
import ErrorNotice from 'components/common/ErrorNotice';
import LoadingSpinner from 'components/common/LoadingSpinner';
import PageNotFoundNotice from 'components/common/PageNotFoundNotice';
import ProjectDashboardTile from 'components/common/ProjectDashboardTile';
import OfflineNoAccessNotice from 'components/offline/OfflineNoAccessNotice';
import {
  SAFETY_INCIDENTS_URL,
  SAFETY_VIOLATIONS_URL,
  SAFETY_DATA_SHEETS_URL,
  SILVERBOOK_URL,
  DRUG_TEST_URL,
  PERMITS_URL
} from 'constants/externalLinks';
import withAuthorization from 'hocs/withAuthorization';
import useObservationCounts from 'hooks/useObservationCounts';
import useProject from 'hooks/useProject';
import useRoles from 'hooks/useRoles';
import useSafeProject from 'hooks/useSafeProject';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import StyledOfflineBanner from 'shared/OfflineBanner';
import useIsManualOfflineModeOn from 'store/manualOfflineMode';
import useIsOnline from 'store/onlineDetection';

import { GET_ENROLLED_ATTENDEES_FOR_PROJECT } from 'graphql/attendee';
import { useQuery } from '@apollo/react-hooks';
import { getScoreIcon } from 'utils/indicatorScoreRange';
import useEnabledFeature from 'hooks/useIsFeatureEnabledForCurrentUser';
import { features } from 'constants/enabledFeatures';

const useStyles = makeStyles(theme => ({
  background: {
    backgroundImage: `url(${bg})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    height: 320,
    width: '100%',
    paddingTop: 0,
    position: 'fixed',
    zIndex: 1,
    [theme.breakpoints.down('lg')]: { height: 260 }
  },
  backgroundOffline: { height: 188 },
  backgroundOverlay: {
    height: 320,
    paddingTop: 0,
    background: `linear-gradient(to bottom,${theme.backgroundLinearGradientTop},${theme.backgroundLinearGradientBottom})`,
    [theme.breakpoints.down('lg')]: { height: 260 }
  },
  backgroundOverlayOffline: { height: 188 },
  button: {
    backgroundColor: theme.palette.invariant.lightGray,
    padding: 0,
    borderRadius: 16,
    marginTop: '1rem',
    marginLeft: theme.spacing(1.5),
    [theme.breakpoints.down('lg')]: { marginTop: 0.5 }
  },
  buttonText: {
    fontSize: '0.75rem',
    fontWeight: 'bold',
    padding: '5px 15px',
    color: theme.palette.invariant.black,
    '&:hover': { color: theme.palette.primary.contrastText }
  },
  projectTitleText: {
    color: '#fff',
    fontSize: '2.5rem',
    marginTop: 112,
    paddingLeft: theme.spacing(1.5),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.up('xl')]: { maxWidth: `calc(1280px - 48px)` },
    [theme.breakpoints.down('xl')]: {
      maxWidth: `calc(1280px - 48px)`,
      fontSize: '2rem'
    },
    [theme.breakpoints.down('xl')]: { maxWidth: `calc(960px - 48px)` },
    [theme.breakpoints.down('lg')]: {
      maxWidth: `calc(600px - 48px)`,
      fontSize: '1.5rem'
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: `calc(300px - 48px)`,
      marginTop: theme.spacing(6)
    }
  },
  projectTitleTextOffline: { marginTop: '1rem' },
  projectInfoText: {
    display: 'inline',
    color: '#fff',
    fontSize: '1.5rem',
    [theme.breakpoints.down('lg')]: { fontSize: '1rem' }
  },
  tileContainer: {
    paddingTop: 340,
    minHeight: 'calc(100vh - 300px)',
    height: 'auto',
    [theme.breakpoints.down('md')]: {
      minHeight: 'calc(100vh - 250px)'
    },
    [theme.breakpoints.down('lg')]: {
      paddingTop: 300
    },
    [theme.breakpoints.down('xl')]: {
      maxWidth: '1600px',
      margin: '0 auto'
    }
  },
  addressTextContainer: { margin: theme.spacing(0, 0, 1, 1.5) },
  headerContainer: {
    [theme.breakpoints.down('md')]: {
      marginTop: 64
    }
  }
}));

export const ProjectDashboardPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const history = useHistory();
  const { projectId } = useParams();
  const { isAdminTypeRole, isSafetyDirector } = useRoles();
  const isAdmin = isAdminTypeRole(projectId);
  const { isOnline } = useIsOnline();
  const {
    myOpenObservationsCount,
    allOpenObservationsCount
  } = useObservationCounts(projectId, isAdmin);

  const { project: projectAsAdmin, error, loading } = useProject(
    projectId,
    !isAdmin
  );

  const { isOfflineModeEnabled } = useIsManualOfflineModeOn();

  const isPreIncidentIndicatorsFeatureEnabled = useEnabledFeature(
    features.preIncidentIndicators
  );

  const {
    safeProject,
    error: safeProjectError,
    loading: safeProjectIsLoading
  } = useSafeProject(projectId);

  const project = isAdmin ? projectAsAdmin : safeProject;
  const street = project?.location?.streetAndNumber ?? null;
  const city = project?.location?.city ?? null;
  const state = project?.location?.stateOrProvince ?? null;
  const zip = project?.location?.zipOrPostalCode ?? null;
  const preIncidentAnalysisProbability =
    project?.preIncidentAnalysis?.probability ?? undefined;

  const { data } = useQuery(GET_ENROLLED_ATTENDEES_FOR_PROJECT, {
    variables: { projectId: projectId },
    pollInterval: 60000,
    fetchPolicy: 'network-only',
    skip: !isAdmin
  });

  const numEnrolledAttendees = data?.enrolledAttendeesOnProject?.length ?? 0;

  if (loading || safeProjectIsLoading) {
    return <LoadingSpinner />;
  }

  if (!isOnline && !project) {
    return (
      <OfflineNoAccessNotice
        message={t('withOnlineAccessOnly.projectUnavailable.message')}
      />
    );
  }

  if (isOnline && (error || safeProjectError)) {
    if (
      error?.message?.includes('not found') ||
      safeProjectError?.message?.includes('not found')
    ) {
      return <PageNotFoundNotice />;
    } else {
      return <ErrorNotice />;
    }
  }

  const numSafeObservationNotifications = isAdmin
    ? allOpenObservationsCount
    : myOpenObservationsCount;

  const tiles = [];
  if (isAdmin) {
    tiles.push(
      <ProjectDashboardTile
        onClick={() => history.push(`/projects/${project?.id}/permissions`)}
        tileImg={theme.permissionsTile}
        tileName={t('projectDashboardPage.tiles.permissions.title')}
        tileDescription={t(
          'projectDashboardPage.tiles.permissions.description'
        )}
        badgeContent={
          project?.accessRequests.length > 0
            ? project?.accessRequests.length
            : null
        }
        isDisabled={!isOnline}
      />
    );
  }
  tiles.push(
    <ProjectDashboardTile
      onClick={() => history.push(`/projects/${project?.id}/observations`)}
      tileImg={theme.safeTile}
      tileName={t('projectDashboardPage.tiles.safe.title')}
      tileDescription={t('projectDashboardPage.tiles.safe.description')}
      badgeContent={numSafeObservationNotifications}
      isDisabled={!isOnline && !isOfflineModeEnabled}
    />
  );
  if (isAdmin) {
    tiles.push(
      <ProjectDashboardTile
        onClick={() => history.push(`/projects/${project?.id}/trade-partners`)}
        tileImg={theme.tradePartnersTile}
        tileName={t('projectDashboardPage.tiles.tradePartners.title')}
        tileDescription={t(
          'projectDashboardPage.tiles.tradePartners.description'
        )}
        isDisabled={!isOnline}
      />,
      <ProjectDashboardTile
        onClick={() => history.push(`/projects/${project?.id}/personnel`)}
        tileImg={theme.personnelTile}
        tileName={t('projectDashboardPage.tiles.personnel.title')}
        tileDescription={t('projectDashboardPage.tiles.personnel.description')}
        isDisabled={!isOnline}
      />,
      <ProjectDashboardTile
        onClick={() => history.push(`/projects/${project?.id}/orientations`)}
        tileImg={theme.orientationsTile}
        tileName={t('projectDashboardPage.tiles.orientations.title')}
        tileDescription={t(
          'projectDashboardPage.tiles.orientations.description'
        )}
        badgeContent={numEnrolledAttendees > 0 ? numEnrolledAttendees : null}
        isDisabled={!isOnline}
      />,
      <ProjectDashboardTile
        onClick={() => history.push(`/projects/${project?.id}/worker-hours`)}
        tileImg={theme.workerHoursTile}
        tileName={t('projectDashboardPage.tiles.workerHours.title')}
        tileDescription={t(
          'projectDashboardPage.tiles.workerHours.description'
        )}
        isDisabled={!isOnline}
      />
    );
  }
  tiles.push(
    <ProjectDashboardTile
      onClick={() => history.push(`/projects/${project?.id}/ahas`)}
      tileImg={theme.ahaTile}
      tileName={t('projectDashboardPage.tiles.aha.title')}
      tileDescription={t('projectDashboardPage.tiles.aha.description')}
      isDisabled={!isOnline}
    />
  );
  if (isAdmin) {
    tiles.push(
      <ProjectDashboardTile
        onClick={() => history.push(`/projects/${project?.id}/safety-hours`)}
        tileImg={theme.safetyHoursTile}
        tileName={t('projectDashboardPage.tiles.safetyHours.title')}
        tileDescription={t(
          'projectDashboardPage.tiles.safetyHours.description'
        )}
        isDisabled={!isOnline}
      />
    );
    if (
      isPreIncidentIndicatorsFeatureEnabled &&
      (project?.districtNumber === 200 ||
        [
          2022158,
          3024279,
          4019173,
          4022199,
          5022179,
          5919028,
          5923059,
          6023108,
          6523001,
          7022174,
          7022176,
          8022216,
          8022221,
          4022204,
          7102121,
          7102345,
          7102346
        ]
          .map(number => number.toString())
          .includes(project?.number ?? 'unknown'))
    ) {
      tiles.push(
        <ProjectDashboardTile
          onClick={() =>
            history.push(`/projects/${project?.id}/pre-incident-indicators`)
          }
          tileImg={theme.preIncidentIndicatorsTile}
          tileName={t('projectDashboardPage.tiles.preIncidentIndicators.title')}
          tileDescription={t(
            'projectDashboardPage.tiles.preIncidentIndicators.description'
          )}
          isDisabled={!isOnline}
          badgeContent={getScoreIcon({
            // TODO: capping the score is not ideal, but red ends up just blending in on light theme.
            // Come up with something better, or just don't use score colors.
            score:
              theme.palette.mode === 'light'
                ? Math.min(preIncidentAnalysisProbability, 0.5)
                : preIncidentAnalysisProbability,
            theme
          })}
        />
      );
    }
  }
  tiles.push(
    <ProjectDashboardTile
      onClick={() => window.open(SAFETY_INCIDENTS_URL, '_blank', 'noopener')}
      tileImg={theme.incidentsTile}
      tileName={'Incidents'}
      tileDescription={'Manage project incidents'}
      isDisabled={!isOnline}
    />,
    <ProjectDashboardTile
      onClick={() => window.open(SAFETY_VIOLATIONS_URL, '_blank', 'noopener')}
      tileImg={theme.violationsTile}
      tileName={'Violations'}
      tileDescription={'Manage project violations'}
      isDisabled={!isOnline}
    />,
    <ProjectDashboardTile
      onClick={() => window.open(SAFETY_DATA_SHEETS_URL, '_blank', 'noopener')}
      tileImg={theme.dataSheetsTile}
      tileName={'Safety Data Sheets'}
      tileDescription={'View safety data sheets'}
      isDisabled={!isOnline}
    />,
    <ProjectDashboardTile
      onClick={() => window.open(SILVERBOOK_URL, '_blank', 'noopener')}
      tileImg={theme.safePracticesTile}
      tileName={t('projectDashboardPage.tiles.safePractices.title')}
      tileDescription={t(
        'projectDashboardPage.tiles.safePractices.description'
      )}
      isDisabled={!isOnline}
    />
  );
  if (isSafetyDirector) {
    tiles.push(
      <ProjectDashboardTile
        onClick={() => window.open(DRUG_TEST_URL, '_blank', 'noopener')}
        tileImg={theme.drugTestTile}
        tileName={t('projectDashboardPage.tiles.drugTest.title')}
        tileDescription={t('projectDashboardPage.tiles.drugTest.description')}
        isDisabled={!isOnline}
      />
    );
  }
  tiles.push(
    <ProjectDashboardTile
      onClick={() => window.open(PERMITS_URL, '_blank', 'noopener')}
      tileImg={theme.permitsTile}
      tileName={t('projectDashboardPage.tiles.permits.title')}
      tileDescription={t('projectDashboardPage.tiles.permits.description')}
      isDisabled={!isOnline}
    />
  );

  const dashboardGridSizing = {
    xs: 4,
    sm: 4,
    md: 3,
    lg: tiles.length <= 12 ? 3 : 2
  };

  return (
    <Fragment>
      {!isOnline && (
        <StyledOfflineBanner
          content={t('projectDashboardPage.offlineMessage')}
        />
      )}
      <Fade in={true} timeout={500}>
        <div
          className={classNames(classes.background, {
            [`${classes.backgroundOffline}`]: !isOnline
          })}>
          <div
            className={classNames(classes.backgroundOverlay, {
              [`${classes.backgroundOverlayOffline}`]: !isOnline
            })}>
            <Grid container direction="column">
              <Container
                sx={{
                  overflow: 'hidden',
                  '&.MuiContainer-maxWidthXl': { maxWidth: 1920 }
                }}
                maxWidth="xl"
                className={classes.headerContainer}>
                <Grid item>
                  <Fade in={true} timeout={500}>
                    <Grid container>
                      <Grid item xs={12}>
                        {project && (
                          <Tooltip title={project.name}>
                            <Typography
                              component="h1"
                              className={classNames(classes.projectTitleText, {
                                [`${classes.projectTitleTextOffline}`]: !isOnline
                              })}>
                              {project.name}
                            </Typography>
                          </Tooltip>
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        className={classes.addressTextContainer}>
                        <Grid container direction="row" spacing={1}>
                          {!isAdmin && (
                            <Grid item>
                              <Typography
                                noWrap={true}
                                className={classes.projectInfoText}>
                                {project?.displayAddress}
                              </Typography>
                            </Grid>
                          )}
                          {isAdmin && (
                            <>
                              {street && (
                                <Grid item>
                                  <Typography
                                    noWrap={true}
                                    className={classes.projectInfoText}>
                                    {street}
                                  </Typography>
                                </Grid>
                              )}
                              {city && state && (
                                <Grid item>
                                  <Typography
                                    noWrap={true}
                                    className={classes.projectInfoText}>
                                    {city}, {state}
                                  </Typography>
                                </Grid>
                              )}
                              {zip && (
                                <Grid item>
                                  <Typography
                                    noWrap={true}
                                    className={classes.projectInfoText}>
                                    {zip}
                                  </Typography>
                                </Grid>
                              )}
                            </>
                          )}
                          <Grid item>
                            <Typography
                              noWrap={true}
                              className={classes.projectInfoText}>
                              | #{project?.number} | Region{' '}
                              {project?.districtNumber}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      {project && isAdmin && (
                        <Grid item xs={12}>
                          <Button
                            data-testid="project-details-button"
                            variant="contained"
                            onClick={() =>
                              history.push(
                                `/projects/${project?.id}/projectDetails`
                              )
                            }
                            className={classes.button}>
                            <Typography className={classes.buttonText}>
                              {t(
                                'projectDashboardPage.updateProjectForm.editProjectDetailsButton'
                              )}
                            </Typography>
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </Fade>
                </Grid>
              </Container>
            </Grid>
          </div>
        </div>
      </Fade>
      <Grid item className={classes.tileContainer}>
        <Container maxWidth={tiles.length <= 12 ? 'lg' : 'xl'}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}>
            {tiles.map((tile, index) => (
              <Grid key={`tileGridItem${index}`} item {...dashboardGridSizing}>
                {tile}
              </Grid>
            ))}
          </Grid>
        </Container>
      </Grid>
    </Fragment>
  );
};

export default withAuthorization(ProjectDashboardPage, {
  personnelOnProject: true
});
